import { cva } from 'class-variance-authority';

export const stickerCVA = cva(
  'rounded-dt-radius-small pt-2 flex h-fit overflow-hidden align-top',
  {
    variants: {
      variant: {
        primary: undefined,
        secondary: undefined,
        accent: undefined,
        emphasis: undefined,
      },
      isTv: {
        true: 'px-dt-spacing-75',
        false: 'px-dt-spacing-50',
      },
      iconPosition: {
        right: 'flex-row-reverse',
        left: undefined,
      },
    },
    compoundVariants: [
      {
        isTv: false,
        variant: 'primary',
        className: 'bg-dt-theme-surface-sticker-sticker-primary',
      },
      {
        isTv: false,
        variant: 'secondary',
        className: 'bg-dt-theme-surface-sticker-sticker-secondary',
      },
      {
        isTv: false,
        variant: 'accent',
        className: 'bg-dt-theme-surface-sticker-sticker-accent',
      },
      {
        isTv: false,
        variant: 'emphasis',
        className: 'bg-dt-theme-surface-sticker-sticker-emphasis',
      },
      {
        isTv: true,
        variant: 'primary',
        className: 'bg-dt-theme-tv-surface-sticker-sticker-primary',
      },
      {
        isTv: true,
        variant: 'secondary',
        className: 'bg-dt-theme-tv-surface-sticker-sticker-secondary',
      },
      {
        isTv: true,
        variant: 'accent',
        className: 'bg-dt-theme-tv-surface-sticker-sticker-accent',
      },
      {
        isTv: true,
        variant: 'emphasis',
        className: 'bg-dt-theme-tv-surface-sticker-sticker-emphasis',
      },
    ],
  },
);

export const stickerIconCVA = cva('flex', {
  variants: {
    variant: {
      primary: undefined,
      secondary: undefined,
      accent: undefined,
      emphasis: undefined,
    },
    isTv: {
      true: '[&>svg]:h-24 [&>svg]:mt-dt-spacing-25',
      false: '[&>svg]:h-14',
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      isTv: false,
      className: 'fill-dt-theme-icon-sticker-icon-primary',
    },
    {
      variant: 'secondary',
      isTv: false,
      className: 'fill-dt-theme-icon-sticker-icon-secondary',
    },
    {
      variant: 'accent',
      isTv: false,
      className: 'fill-dt-theme-icon-sticker-icon-accent',
    },
    {
      variant: 'emphasis',
      isTv: false,
      className: 'fill-dt-theme-icon-sticker-icon-emphasis',
    },
    {
      variant: 'primary',
      isTv: true,
      className: 'fill-dt-theme-tv-icon-sticker-icon-primary',
    },
    {
      variant: 'secondary',
      isTv: true,
      className: 'fill-dt-theme-tv-icon-sticker-icon-secondary',
    },
    {
      variant: 'accent',
      isTv: true,
      className: 'fill-dt-theme-tv-icon-sticker-icon-accent',
    },
    {
      variant: 'emphasis',
      isTv: true,
      className: 'fill-dt-theme-tv-icon-sticker-icon-emphasis',
    },
  ],
});

export const stickerLabelCVA = cva(
  [
    'text-ellipsis overflow-hidden whitespace-nowrap',
    'm-dt-spacing-none', // ! temporary fallback for missing `@tailwind base`
  ],
  {
    variants: {
      isTv: {
        true: undefined,
        false: undefined,
      },
      variant: {
        primary: undefined,
        secondary: undefined,
        accent: undefined,
        emphasis: undefined,
      },
      labelStyle: {
        regular: undefined,
        semibold: undefined,
      },
      hasIcon: {
        true: undefined,
        false: undefined,
      },
      iconPosition: {
        left: undefined,
        right: undefined,
      },
    },
    compoundVariants: [
      // Font color
      {
        isTv: true,
        variant: 'primary',
        className: 'text-dt-theme-tv-text-sticker-sticker-primary',
      },
      {
        isTv: true,
        variant: 'secondary',
        className: 'text-dt-theme-tv-text-sticker-sticker-secondary',
      },
      {
        isTv: true,
        variant: 'accent',
        className: 'text-dt-theme-tv-text-sticker-sticker-accent',
      },
      {
        isTv: true,
        variant: 'emphasis',
        className: 'text-dt-theme-tv-text-sticker-sticker-emphasis',
      },
      {
        isTv: false,
        variant: 'primary',
        className: 'text-dt-theme-text-sticker-sticker-primary',
      },
      {
        isTv: false,
        variant: 'secondary',
        className: 'text-dt-theme-text-sticker-sticker-secondary',
      },
      {
        isTv: false,
        variant: 'accent',
        className: 'text-dt-theme-text-sticker-sticker-accent',
      },
      {
        isTv: false,
        variant: 'emphasis',
        className: 'text-dt-theme-text-sticker-sticker-emphasis',
      },
      // Responsive typography
      {
        isTv: true,
        labelStyle: 'regular',
        className: 'font-dt-system-hind-label-label-5-regular-tv',
      },
      {
        isTv: false,
        labelStyle: 'regular',
        className: 'font-dt-system-hind-label-label-5-regular',
      },
      {
        isTv: true,
        labelStyle: 'semibold',
        className: 'font-dt-system-hind-label-label-5-semi-bold-tv',
      },
      {
        isTv: false,
        labelStyle: 'semibold',
        className: 'font-dt-system-hind-label-label-5-semi-bold',
      },
      // Icon spacing
      {
        hasIcon: true,
        iconPosition: 'left',
        className: 'ml-dt-spacing-25',
      },
      {
        hasIcon: true,
        iconPosition: 'right',
        className: 'mr-dt-spacing-25',
      },
    ],
  },
);

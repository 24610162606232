import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import { Ratio, ResizeMode } from '@dce-front/onewebapp-utils';
import { withTwoDecimals } from '../utils/utils-helper';
import { getHeightMediaFromRatio } from './getHeightMediaFromRatio';

export type MediaDimensions = {
  heightMediaCard: number;
  widthMediaCard: number;
};

function transformRemToPixel(rem: number, resizeMode?: ResizeMode) {
  return resizeMode === ResizeMode.RESIZE_720P
    ? withTwoDecimals(rem * 10.6666666667)
    : rem * 16;
}
/**
 * as getMediaDimensions function returns the value as REM, this function will transform the height and width to px
 * it will depends on resizeMode
 * if auto (1080p) it will multiply the values by 16
 * if 720p it will multiply the values by 10.6666666667
 * @param mediaDimensions MediaDimensions
 * @param resizeMode ResizeMode
 * @returns MediaDimensions
 */
export function transformMediaDimensionsToPx(
  { heightMediaCard, widthMediaCard }: MediaDimensions,
  resizeMode?: ResizeMode,
): MediaDimensions {
  return {
    widthMediaCard: transformRemToPixel(widthMediaCard, resizeMode),
    heightMediaCard: transformRemToPixel(heightMediaCard, resizeMode),
  };
}

const getHeightOfMediaFooter = (titleDisplayMode: TitleDisplayMode) => {
  let mediaFooterHeight = 0;

  // from space-y-dt-spacing-200
  const paddingBetweenFooterAndImage = 1;

  // from MediaFooterWrapper.css
  if (titleDisplayMode === TitleDisplayMode.TitleOnly) {
    mediaFooterHeight = 4.5 + paddingBetweenFooterAndImage;
  }
  if (titleDisplayMode === TitleDisplayMode.All) {
    mediaFooterHeight = 6.625 + paddingBetweenFooterAndImage;
  }

  return mediaFooterHeight;
};

const VIEWPORT_WIDTH = 120; // value as rem --> 1920px for 1080p, 1280px for 720p
const DEFAULT_NUMBER_OF_ITEMS = 4;

export type GetMediaDimensionsProps = {
  /**
   * ratio of a media. ex: 169, 43...
   */
  ratio: Ratio;
  /**
   * if undefined or equals to TitleDisplayMode.None, it won't add calculation for mediaFooter
   * else it will increase the height of media
   */
  titleDisplayMode?: TitleDisplayMode;
  /**
   * define of many items are shown on screen
   */
  numberOfItemOnScreen?: number;
  /**
   * padding left and right of list with rem unit of measurement
   * default value: 4
   */
  paddingLeftRight?: number;
  /**
   * padding left and right of list item with rem unit of measurement
   * default value: 1.625
   */
  paddingLeftRightItem?: number;
  /**
   * padding top and bottom of list item with rem unit of measurement
   * default value: 0.3125
   */
  paddingTopBottomItem?: number;
};

/**
 * compute the exact dimensions (width and height) of a media card
 * The unit of measurement is rem
 * if you want to convert it to pixel, you can call transformMediaDimensionsToPx function outside
 * @param mediaDimensionsProps GetMediaDimensionsProps
 * @returns MediaDimensions
 */
export function getMediaDimensions({
  ratio,
  titleDisplayMode = TitleDisplayMode.None,
  numberOfItemOnScreen = DEFAULT_NUMBER_OF_ITEMS,
  paddingLeftRight = 4,
  paddingLeftRightItem = 1.625,
  paddingTopBottomItem = 0.3125,
}: GetMediaDimensionsProps): MediaDimensions {
  // Compute the width of media card by removing the padding from the viewport and divide by the number of items to show
  const widthMediaCard =
    (VIEWPORT_WIDTH - paddingLeftRight * 2) / numberOfItemOnScreen;

  // Get the value of the media card without the padding of item, this will help up to define the height of image
  const widthMediaCardWithoutPadding =
    widthMediaCard - paddingLeftRightItem * 2;

  // Determine the height of image from the width of image and the ratio
  const heightMediaImage = getHeightMediaFromRatio(
    widthMediaCardWithoutPadding,
    ratio,
  );

  // Define the height of MediaFooter when titleDisplayMode is provided
  const heightOfMediaFooter = getHeightOfMediaFooter(titleDisplayMode);

  // Compute the height of media card by adding the height of media footer including the gap and the padding top/ bottom of item
  const heightMediaCard =
    heightMediaImage + heightOfMediaFooter + paddingTopBottomItem * 2;

  return { widthMediaCard, heightMediaCard };
}

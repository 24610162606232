import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import { type StickerProps, PlayerPlaySvg } from '@dce-front/dive';
import { useInvariantSelector } from '../hooks/useInvariantSelector';
import { langKeySelector } from '../../store/slices/application-selectors';
import { useTranslation } from '../../lang';
import {
  MAP_HODOR_BOTTOM_STICKER_TYPE_TO_DIVE_STICKER_PROPS,
  MAP_HODOR_TOP_STICKER_TYPE_TO_DIVE_STICKER_PROPS,
} from '../../constants/stickers';
import { dateFormat, getCurrentTimestamp } from '../date/date-helper';

// TODO This constant will be removed when all types of Sticker will be handled by Hodor
const TOP_AVAILABLE_HODOR_STICKER_TYPES: ApiV2SpyroStrateContentSticker['type'][] =
  [
    'coming-soon',
    'date',
    'free-to-air',
    'get',
    'offered-content',
    'subscribe',
    'theatrical-release',
  ];

const BOTTOM_AVAILABLE_HODOR_STICKER_TYPES: ApiV2SpyroStrateContentSticker['type'][] =
  ['duration'];

// These types of sticker will be soon available, so we only add the toggle for these ones
// They will be removed progressively
const UPCOMING_HODOR_STICKERS: ApiV2SpyroStrateContentSticker['type'][] = [
  'last-days',
];

/**
 * This function will extract the hodor sticker from the stickers object returned by hodor
 * It will also check if the type of sticker is available, and check the feat toggle for some
 * types not handle now by Hodor (see: https://canal-wiki.canal-plus.com/display/2M/Chantiers+stickers+centralisation)
 * @param stickers ApiV2SpyroStrateContentSticker[]
 * @param isFeatUpcomingHodorSticker boolean
 * @returns
 */
export const getHodorSticker = ({
  stickers,
  isFeatUpcomingHodorSticker = false,
  position = 'top',
}: {
  stickers?: ApiV2SpyroStrateContentSticker[];
  isFeatUpcomingHodorSticker?: boolean;
  position?: 'top' | 'bottom';
}): ApiV2SpyroStrateContentSticker | undefined => {
  if (!stickers?.[0]) {
    return undefined;
  }

  const topAvailableHodorStickers = isFeatUpcomingHodorSticker
    ? TOP_AVAILABLE_HODOR_STICKER_TYPES.concat(UPCOMING_HODOR_STICKERS)
    : TOP_AVAILABLE_HODOR_STICKER_TYPES;

  const availableHodorStickers =
    position === 'top'
      ? topAvailableHodorStickers
      : BOTTOM_AVAILABLE_HODOR_STICKER_TYPES;

  return stickers.find((sticker) => {
    return availableHodorStickers.includes(sticker.type);
  });
};

export const STICKER_LABEL_LIVE = 'LIVE';

export type UseGetDiveStickerPropsArgs = {
  /**
   * Timestamp (in milliseconds) for the **end** of the media associated with the sticker.
   * Used to determine when the media ends.
   */
  endTime?: number;

  /**
   * Timestamp (in milliseconds) for the **start** of the media associated with the sticker.
   * Used to determine when the media starts, such as for upcoming events or programs.
   */
  position?: 'bottom' | 'top';

  /**
   * Timestamp (in milliseconds) for the **start** of the media associated with the sticker.
   * Used to determine when the media starts, such as for upcoming events or programs.
   */
  startTime?: number;

  /**
   * Data for HODOR-generated Stickers, containing the sticker `type` and `label`.
   * @see {@link https://canal-wiki.canal-plus.com/pages/viewpage.action?pageId=236094684 Hodor Sticker specs}
   */
  sticker?: ApiV2SpyroStrateContentSticker;

  /**
   * Live content override, used to display a "LIVE" sticker.
   * Only used when the sticker strate is not available.
   */
  showLiveSticker?: boolean;
};

export const useGetDiveStickerProps = ({
  sticker,
  startTime,
  endTime,
  showLiveSticker,
  position = 'top',
}: UseGetDiveStickerPropsArgs):
  | Pick<StickerProps, 'variant' | 'label' | 'icon' | 'labelStyle'>
  | undefined => {
  const langKey = useInvariantSelector(langKeySelector);
  const { t } = useTranslation();

  if (sticker && sticker.label) {
    const baseDiveStickerProps =
      position === 'bottom'
        ? MAP_HODOR_BOTTOM_STICKER_TYPE_TO_DIVE_STICKER_PROPS[sticker.type]
        : MAP_HODOR_TOP_STICKER_TYPE_TO_DIVE_STICKER_PROPS[sticker.type];

    if (baseDiveStickerProps) {
      return {
        ...baseDiveStickerProps,
        label: sticker.label,
        icon:
          sticker.type === 'offered-content' ? (
            <PlayerPlaySvg title={sticker.label} />
          ) : undefined,
      };
    }
    return undefined;
  }

  if (showLiveSticker) {
    return {
      variant: 'emphasis',
      labelStyle: 'semibold',
      label: STICKER_LABEL_LIVE,
    };
  }

  if (startTime && endTime && getCurrentTimestamp() <= startTime) {
    const stickerDateLabel = dateFormat(t, startTime, langKey, true);
    if (stickerDateLabel) {
      return {
        variant: 'primary',
        labelStyle: 'regular',
        label: stickerDateLabel,
      };
    }
  }

  return undefined;
};

exports.ratios = {
  square: {
    width: 1,
    height: 1,
  },
  169: {
    width: 16,
    height: 9,
  },
  166: {
    width: 16,
    height: 6,
  },
  23: {
    width: 2,
    height: 3,
  },
  34: {
    width: 3,
    height: 4,
  },
  43: {
    width: 4,
    height: 3,
  },
  47: {
    width: 4,
    height: 7,
  },
};

exports.contentGrid = {
  squarenormal: {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 4,
  },
  '23normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 9,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 9,
    wide: 11,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '47normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
};

/**
 * Same as default contentRow but limited to laptop amount,
 * since DetailPage limited by a max-width of 1280px
 */
exports.contentGridDetailV5 = {
  squarenormal: {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 2,
    large_desktop: 2,
    wide: 2,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '23normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '47normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
};

exports.contentGridTv = {
  squarenormal: {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '23normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 6,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '47normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
};

exports.contentGridHighlight = {
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
};

exports.contentRow = {
  squarenormal: {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 4,
  },
  '23normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 9,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 9,
    wide: 11,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
  '47normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 7,
    large_desktop: 8,
    wide: 10,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
};

exports.contentRowLogoTyped = {
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
};

exports.contentRowTv = {
  squarenormal: {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169large': {
    mobile: 1,
    tablet: 3,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
  '23normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '47normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 5,
    wide: 5,
  },
};

exports.contentRowGuideTv = {
  '169normal': {
    mobile: 5,
    tablet: 5,
    laptop: 5,
    desktop: 5,
    large_desktop: 5,
    wide: 5,
  },
};

exports.daySelectorTv = {
  '169normal': {
    mobile: 7,
    tablet: 7,
    laptop: 7,
    desktop: 7,
    large_desktop: 7,
    wide: 7,
  },
};

exports.contentRowImmersive = {
  squarenormal: {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 3,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '23normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '34normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '43normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 5,
    wide: 6,
  },
  '47normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
};

/**
 * Same as default contentRow but limited to laptop amount,
 * since DetailPage limited by a max-width of 1280px
 */
exports.contentRowDetailV5 = {
  squarenormal: {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  squarelarge: {
    mobile: 2,
    tablet: 3,
    laptop: 3,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '166large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
  '169normal': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 3,
  },
  '23normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '23large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '34normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '34large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '43normal': {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '43large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
  '47normal': {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  '47large': {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
};

exports.contentRowCreativeMediaInDetailV5 = {
  '169normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 3,
    wide: 2,
  },
};

exports.creativeMedia = {
  '169normal': {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
    large_desktop: 4,
    wide: 4,
  },
};

exports.liveTv = {
  169: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 6,
    wide: 7,
  },
};

exports.liveTvV5 = {
  169: {
    mobile: 4,
    tablet: 4,
    laptop: 4,
    desktop: 4,
    large_desktop: 4,
    wide: 4,
  },
};

exports.mosaic = {
  43: {
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktop: 5,
    large_desktop: 5,
    wide: 6,
  },
};

exports.smallMosaic = {
  43: {
    mobile: 5,
    tablet: 8,
    laptop: 10,
    desktop: 10,
    large_desktop: 10,
    wide: 10,
  },
};

exports.liveGridEditFavorites = {
  43: {
    mobile: 3,
    tablet: 4,
    laptop: 5,
    desktop: 6,
    large_desktop: 8,
    wide: 8,
  },
};

exports.liveGridEditFavoritesTv = {
  43: {
    mobile: 5,
    tablet: 5,
    laptop: 5,
    desktop: 5,
    large_desktop: 5,
    wide: 5,
  },
};

exports.multiLiveSetup = {
  169: {
    mobile: 1,
    tablet: 3,
    laptop: 4,
    desktop: 4,
    large_desktop: 5,
    wide: 6,
  },
};

exports.episodes = {
  169: {
    mobile: 2,
    tablet: 3,
    laptop: 3,
    desktop: 4,
    large_desktop: 4,
    wide: 5,
  },
};

exports.showcase = {
  34: {
    mobile: 3,
    tablet: 5,
    laptop: 6,
    desktop: 6,
    large_desktop: 6,
    wide: 6,
  },
  43: {
    mobile: 4,
    tablet: 8,
    laptop: 8,
    desktop: 8,
    large_desktop: 8,
    wide: 8,
  },
};

exports.carrousel = {
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 2,
    large_desktop: 2,
    wide: 3,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 2,
    large_desktop: 2,
    wide: 3,
  },
};

exports.carrouselTv = {
  '166normal': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 2,
    large_desktop: 2,
    wide: 2,
  },
  '169large': {
    mobile: 1,
    tablet: 2,
    laptop: 2,
    desktop: 2,
    large_desktop: 2,
    wide: 2,
  },
};

import {
  StickerVariant,
  StickerLabelStyle,
  type StickerProps,
} from '@dce-front/dive';
import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';

export const MAP_HODOR_TOP_STICKER_TYPE_TO_DIVE_STICKER_PROPS: Record<
  ApiV2SpyroStrateContentSticker['type'],
  Pick<StickerProps, 'variant' | 'labelStyle'>
> = {
  'coming-soon': {
    variant: StickerVariant.Primary,
    labelStyle: StickerLabelStyle.Regular,
  },
  date: {
    variant: StickerVariant.Primary,
    labelStyle: StickerLabelStyle.Regular,
  },
  'free-to-air': {
    variant: StickerVariant.Primary,
    labelStyle: StickerLabelStyle.Regular,
  },
  get: {
    variant: StickerVariant.Accent,
    labelStyle: StickerLabelStyle.Semibold,
  },
  'last-days': {
    variant: StickerVariant.Emphasis,
    labelStyle: StickerLabelStyle.Semibold,
  },
  live: {
    variant: StickerVariant.Emphasis,
    labelStyle: StickerLabelStyle.Semibold,
  },
  'offered-content': {
    variant: StickerVariant.Secondary,
    labelStyle: StickerLabelStyle.Semibold,
  },
  subscribe: {
    variant: StickerVariant.Accent,
    labelStyle: StickerLabelStyle.Semibold,
  },
  'theatrical-release': {
    variant: StickerVariant.Primary,
    labelStyle: StickerLabelStyle.Regular,
  },
};

export const MAP_HODOR_BOTTOM_STICKER_TYPE_TO_DIVE_STICKER_PROPS: Record<
  ApiV2SpyroStrateContentSticker['type'],
  Pick<StickerProps, 'variant' | 'labelStyle'>
> = {
  duration: {
    variant: StickerVariant.Primary,
    labelStyle: StickerLabelStyle.Regular,
  },
};
